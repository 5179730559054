import { useWeb3React } from '@web3-react/core'
import useInterval from "@use-it/interval";
import { useEffect, useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { convertToEther, getContract } from "../../utils";
import useAuth from '../../hooks/useAuth';

const ReferralBlock = () => {
  const [copied, setCopied] = useState(false);
  const { account } = useWeb3React();
  const { login } = useAuth()

  const buildReferralUrl = () => {
    return window.location.protocol + "//" + window.location.host + "?ref=" + account;
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied])

  if (!account) {
    return <span className="inline-block my-4 border border-indigo-400 p-3 px-8 rounded-xl cursor-not-allowed text-white" onClick={login}>
      <span className="text-base font-semibold">Wallet not connected</span>
    </span>;
  }

  return <>
    <div className="flex bnb -mr-4">
      <span className="inline-block mt-2 border border-gray-600 overflow-scroll text-sm bold p-2 rounded-xl bg-white">
        <span className="text-base font-semibold whitespace-nowrap">{buildReferralUrl()}</span>
      </span>
      <CopyToClipboard text={buildReferralUrl()} onCopy={() => setCopied(true)}>
        <span className="inline-block h-14 w-24 rounded p-2 cursor-pointer -ml-5  border-box text-white flex flex-col justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentcolor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
          </svg>
        </span>
      </CopyToClipboard>
    </div>
    {copied && <span className="text-xs inline-block bg-yellow-400 rounded text-indigo-600 mt-2 px-2 py-1 text-center">Copied</span>}
  </>
}

const KeyValuePair = ({ input, value }) => {
  return <>
    <div className="text-center mt-2 mx-auto text-white">
      <div className="font-medium text-3xl">{value}</div>
      <div className="text-sm font-medium text-indigo-200">{input}</div>
    </div>
  </>
}

const UserReferralStats = () => {
  const [totalReferrals, setTotalReferrals] = useState([0, 0, 0]);
  const [referralEarning, setReferralEarning] = useState(0);
  const contract = getContract();
  const { account } = useWeb3React();

  const refresh = async () => {
    console.log(`Refreshing referral Stats`);

    if (!account) return;

    const fetchFromChain = async () => {
      const referralEarning = await contract.methods.getUserReferralTotalBonus(account).call();
      const refs = await contract.methods.getUserDownlineCount(account).call();
      setTotalReferrals(parseInt(refs[0]) + parseInt(refs[1]) + parseInt(refs[2]));
      setReferralEarning(convertToEther(referralEarning).toFixed(3));
    }
    fetchFromChain();
  }

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(async () => refresh(), 5000);

  return <>
    <div className="w-full flex flex-wrap justify-between mt-8 bnb px-2 whitespace-nowrap">
      <KeyValuePair value={referralEarning} input="Total referral earning" />
      <KeyValuePair value={totalReferrals} input="Total users referred" />
    </div>
  </>
}

const Referral = () => {
  return (
    <div className="h-full w-full mt-4 md:mt-0 text-center ">
      <div className="h-full border rounded-xl shadow-2xl	 border-gray-500 whitespace-normal bg-indigo-600 text-white py-2">
        <div className="w-full">
          <div className="w-full px-2">
            <div className="flex-1 mt-6 px-2">
              <div className="text-md mb-4 font-medium tracking-wide leading-tight text-left">Referral Bonus</div>
              <ReferralBlock />
            </div>
            
          </div>
          <div className="flex mt-6">
          <UserReferralStats />
          <div className="text-xs mt-4 text-white leading-4 tracking-tight md:leading-5 w-2/5 mt-8">
            <div className="text-center text-md font-semibold">Rules</div>
            <div>1) 5.5% from each lvl 1 referral deposits</div>
            <div>2) 3.0% from each lvl 2 referral deposits</div>
            <div>3) 0.5% from each lvl 3 referral deposits</div>
          </div>
          </div>
        </div>
      </div>
    </div>)
}

export default Referral;