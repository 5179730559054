import useInterval from "@use-it/interval";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import { convertToEther, getContract } from "../../utils";

const Stat = () => {
  const [staked, setStaked] = useState({ prev: 0, current: 0 });
  const [contractBalance, setContractBalance] = useState({ prev: 0, current: 0 });
  const contract = getContract();

  const refresh = async () => {
    console.log(`Refreshing Contract Stats`);
    const totalStaked = await contract.methods.totalStaked().call();
    const totalBalance = await contract.methods.getContractBalance().call();
    setStaked({ prev: staked.current, current: convertToEther(totalStaked, 3) });
    setContractBalance({ prev: contractBalance.current, current: convertToEther(totalBalance, 3) });
  }

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useInterval(refresh, 5000);

  console.log(staked);

  const Icon = () => {
    return (
      <div className="h-16 w-16 mr-8">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z" clipRule="evenodd" />
        </svg>
      </div>
    )
  }

  const Locked = () => {
    return (
      <div className="h-16 w-16 mr-8">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
        </svg>
      </div>
    )

  }

  return <>
    <div className="mx-auto w-full">
      <div className="w-full mt-4 lg:mt-0 flex flex-col md:flex-row justify-center">
        <div className="mx-auto md:mx-4 mt-2 shadow-xl bg-indigo-600 text-white p-5 w-full max-w-xs rounded-xl mt-0 lg:mt-4 flex items-center mx-4">
          <Icon />
          <div>
            <div className="font-medium text-4xl md:text-5xl"><CountUp separator={","} start={staked.prev} decimals={3} end={staked.current} /></div>
            <div className="text-sm font-medium">Total BNB Deposited</div>
          </div>
        </div>
        <div className="mx-auto md:mx-4 mt-2 shadow-xl bg-indigo-600 text-white p-5 w-full max-w-xs rounded-xl mt-0 lg:mt-4 flex items-center mx-4">
          <Locked />
          <div>
            <div className="font-medium text-4xl md:text-5xl "><CountUp separator={","} start={contractBalance.prev} decimals={3} end={contractBalance.current} /></div>
            <div className="text-sm font-medium">Total BNB Locked</div>
          </div>
        </div>
      </div>
    </div>
  </>
}

// const Jargon = () => {
//   return <>
//     <div className="w-full h-full text-indigo-700 text-xs lg:text-sm px-1 lg:px-4">
//       <div className="rounded p-4 leading-4 tracking-tighter lg:tracking-wide lg:leading-5 h-full flex flex-col justify-center" style={{ backgroundImage: "url('/bg.png')" }}>
//         <p>Total income: based on your tarrif plan (from <b>5%</b> to <b>8%</b> daily)</p>
//         <p>Basic interest rate: <b>+0.5%</b> every <b>24 hours</b> - only for new deposits</p>
//         <p>Minimal deposit: <b>0.01 BNB</b>, no maximal limit</p>
//         <p>Earnings <b>every moment</b>, withdraw <b>any time</b> (if you use capitalization of interest you can withdraw only after end of your deposit)</p>
//       </div>
//     </div>
//   </>
// }

const ContractStats = () => {
  return <div className="w-full justify-between mx-auto flex flex-wrap items-stretch mt-4 lg:mt-8">
    {/* <div className="w-full lg:w-2/3"><Jargon /></div> */}
    <div className="w-full"><Stat /></div>

  </div>
}

export default ContractStats;