import UserStats from "../UserStats";
import Referral from "./referral";


const UserStatAndReferral = () => {
  return <>
    <div className="w-full max-w-6xl justify-between mx-auto flex flex-wrap items-stretch mt-2 px-2 mt-4">
      <div className="text-2xl lg:text-3xl my-8 mb-4 underline text-center w-full">Earnings & Referral Bonus</div>
      <div className="flex flex-col md:flex-row w-full h-full">
        <div className="w-full md:w-1/3">
          <UserStats />
        </div>
        <div className="w-full md:w-2/3">
          <Referral />
        </div>
      </div>


    </div>
  </>
}

export default UserStatAndReferral;