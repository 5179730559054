import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Countdown from 'react-countdown';


const MySwal = withReactContent(Swal);

const Completionist = () => {
  return <>
    <button onClick={() => MySwal.close()} className={`bg-yellow-400 inline text-indigo-600 shadow-8xl mx-4 text-2xl text-center font-medium py-2 px-8 hover:border-red-800 rounded-xl`}>Stake Now!</button>

  </>
};

function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}


const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <span className="text-indigo-700 text-center text-4xl">
      <span className="block text-3xl mb-3 font-semibold tracking-wider"><span className="border-b">Starting in</span></span>
      <span className="block my-4">
        <span className="p-1">{pad(days, 2)} :</span>
        <span className="p-1">{pad(hours, 2)} :</span>
        <span className="p-1">{pad(minutes, 2)} :</span>
        <span className="p-1">{pad(seconds, 2)}</span>
        <span className="block text-xs">{new Date(1618714800035).toUTCString()}</span>
      </span>

      <a className="block text-2xl mt-4 text-indigo-600" target="_blank" rel="noreferrer" href="https://t.me/bnbbankapp">Join Our <span className="underline">Telegram</span> for more details</a>

    </span>;
  }
};

export const TutorialPopup = () => {
  MySwal.fire({
    title: <p className="border-b-0 text-indigo-700 underline">BNB BANK V2</p>,
    width: 800,
    showConfirmButton: false,
    allowOutsideClick: false,
    // showCancelButton: true,
    // cancelButtonText: 'Close',
    // cancelButtonColor: 'Red',
    // confirmButtonText: 'Don\'t show again',
    html: <div className="w-full text-white">
      <div className=" rounded p-2 text-lg tracking-wide">
        {/* <span className="underline mx-1">Stake BNB</span> to earn upto <span className="underline mx-1">14%</span> return per day */}
        <span className="block text-center mt-2">
          <Countdown
            date={new Date(1618714800035)}
            renderer={renderer}
            zeroPadTime={2}
          />
        </span>
      </div>

      <div className="border rounded p-2 bg-indigo-600 text-lg mt-4">
        3-level referral commission - 5.5% / 3% / 0.5%
      </div>



      <div className="font-normal text-sm mt-1 mt-2">
        <table className="w-full">
          <thead className="w-full">
            <tr className="bg-gray-700 text-indigo-300 whitespace-none text-sm">
              <th className="p-0.5 py-2 border">Investing Period</th>
              <th className="p-0.5 py-2 border">Compounding</th>
              <th className="p-0.5 py-2 border">Daily Return</th>
              <th className="p-0.5 py-2 border">Total Profit</th>
              <th className="p-0.5 py-2 border">Withdrawal Time</th>
            </tr>
          </thead>
          <tbody className="bg-gray-200">
            <tr className="bg-white text-indigo-700 border-2 border-indigo-200 text-center font-semibold">
              <td className="border-r py-0.5">14 Days</td>
              <td className="border-r py-0.5">No</td>
              <td className="border-r py-0.5">9%</td>
              <td className="border-r py-0.5">126%</td>
              <td className="border-r py-0.5">Any Time</td>
            </tr>
            <tr className="bg-white text-indigo-700 border-2 border-indigo-200 text-center font-semibold">
              <td className="border-r py-0.5">21 Days</td>
              <td className="border-r py-0.5">No</td>
              <td className="border-r py-0.5">7.5%</td>
              <td className="border-r py-0.5">157.5%</td>
              <td className="border-r py-0.5">Any Time</td>
            </tr>
            <tr className="bg-white text-indigo-700 border-2 border-indigo-200 text-center font-semibold">
              <td className="border-r py-0.5">28 Days</td>
              <td className="border-r py-0.5">No</td>
              <td className="border-r py-0.5">6%</td>
              <td className="border-r py-0.5">168%</td>
              <td className="border-r py-0.5">Any Time</td>
            </tr>
            <tr className="bg-white text-indigo-700 border-2 border-indigo-200 text-center font-semibold">
              <td className="border-r py-0.5">14 Days</td>
              <td className="border-r py-0.5">Yes</td>
              <td className="border-r py-0.5">13.71%</td>
              <td className="border-r py-0.5">193%</td>
              <td className="border-r py-0.5">14 Days</td>
            </tr>
            <tr className="bg-white text-indigo-700 border-2 border-indigo-200 text-center font-semibold">
              <td className="border-r py-0.5">21 Days</td>
              <td className="border-r py-0.5">Yes</td>
              <td className="border-r py-0.5">13.1%</td>
              <td className="border-r py-0.5">275%</td>
              <td className="border-r py-0.5">21 Days</td>
            </tr>
            <tr className="bg-white text-indigo-700 border-2 border-indigo-200 text-center font-semibold">
              <td className="border-r py-0.5">28 Days</td>
              <td className="border-r py-0.5">Yes</td>
              <td className="border-r py-0.5">10.42%</td>
              <td className="border-r py-0.5">292%</td>
              <td className="border-r py-0.5">28 Days</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  }).then(r => {
    if (r.isConfirmed) {
      document.cookie = 'notShow=true; path=/'
    }
  })
}

export const ToastPopup = (message, type = 'success') => {
  Swal.fire({
    text: message,
    target: `${type === 'success' ? '#success-target' : '#error-target'}`,
    showConfirmButton: false,
    timer: 3000,
    customClass: {
      container: 'position-absolute'
    },
    toast: true,
    position: 'top-right'
  })
}

export const RedirectPopup = () => {
  MySwal.fire({
    title: <p className="border-b-0 text-indigo-700">BNB Bank</p>,
    width: 800,
    confirmButtonText: "Switch to V2!",
    // showCancelButton: true,
    // cancelButtonText: 'Close',
    // cancelButtonColor: 'Red',
    // confirmButtonText: 'Don\'t show again',
    html: <div className="w-full text-indigo-700">
      <div className="text-xl font-bold tracking-wide md:text-md w-full mx-auto justify-center items-center px-4 py-2 rounded-xl text-md mt-2">
        <span className="underline mx-1">BNB Bank</span> run its course  <span className="underline mx-1">successfully</span> and is no longer generating profits.
        <br></br>
        <div className="text-sm md:text-lg font-medium mt-1">Please switch to <a className="underline" href="/v2">BNBBank V2</a> to invest in the 2nd version</div>
      </div>
    </div>
  })
}