import './App.css';
import Nav from './componentsv2/Nav';
import Footer from './componentsv2/Footer';
import Cookie from './componentsv2/Cookie';

import { useEffect } from 'react';
import useAuth from './hooks/useAuth';
import Plans from './componentsv2/Plan';
import UserStatsAndReferral from './componentsv2/UserStatsAndReferral';
import UserPlans from './componentsv2/UserPlans';
import ContractStats from './componentsv2/ContractStats';


function AppV2() {
  const { login } = useAuth();

  useEffect(() => {
    login();
    // wallet.ethereum.Contract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="antialiased w-full mx-auto px-2 app">
      <div className="max-w-6xl mx-auto ">
        <Nav />
        <div className="my-8 md:my-16 p-2 leading-tight text-center text-indigo-700">
          <div className="text-xl md:text-4xl  font-bold tracking-wide md:text-md w-full mx-auto justify-center items-center px-4 py-2 rounded-xl text-md mt-2">
            <span className="underline mx-1">Stake BNB</span> to earn upto <span className="underline mx-1">14%</span> return per day
            <div className="text-sm md:text-lg font-medium mt-1">With capitalization of interest and multi level referrals</div>
          </div>
        </div>
        <ContractStats />
        <Plans />
        {/* <Information /> */}
        <UserStatsAndReferral />
        <UserPlans />
        <Footer />
        <Cookie />
      </div>
    </div>
  );
}

export default AppV2;
