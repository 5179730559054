import { useEffect, useState } from "react";
import { getContract, PLANS } from "../../utilsv2";
import Plan from "./Plan"

const getSimpleReturn = (percent, days) => (days * percent);
export const getCompoundReturn = (percent, days) => Number(Number((Math.pow((1 + percent / 100), days) - 1) * 100).toFixed(2));
export const getPlanId = (days, compound) => (days / 7 - 2) + (compound ? 3 : 0)



const Plans = () => {

  const [plans, setPlans] = useState(Object.values(PLANS));
  const contract = getContract();

  const refresh = async () => {
    console.log(`Refreshing Plans`);

    const fetchFromChain = async () => {
      const p1 = parseInt(await contract.methods.getPercent(0).call()) / 10;
      const p2 = parseInt(await contract.methods.getPercent(1).call()) / 10;
      const p3 = parseInt(await contract.methods.getPercent(2).call()) / 10;
      const p4 = parseInt(await contract.methods.getPercent(3).call()) / 10;
      const p5 = parseInt(await contract.methods.getPercent(4).call()) / 10;
      const p6 = parseInt(await contract.methods.getPercent(5).call()) / 10;

      const [s1, s2, s3] = [getSimpleReturn(p1, 14), getSimpleReturn(p2, 21), getSimpleReturn(p3, 28)];
      const [s4, s5, s6] = [getCompoundReturn(p4, 14), getCompoundReturn(p5, 21), getCompoundReturn(p6, 28)];

      const getDailyReturn = (s, d) => Number(Number(s / d));

      const newPlans = [];
      newPlans.push([getDailyReturn(s1, 14), 14, s1]);
      newPlans.push([getDailyReturn(s2, 21), 21, s2]);
      newPlans.push([getDailyReturn(s3, 28), 28, s3]);
      newPlans.push([getDailyReturn(s4, 14), 14, s4]);
      newPlans.push([getDailyReturn(s5, 21), 21, s5]);
      newPlans.push([getDailyReturn(s6, 28), 28, s6]);

      setPlans(newPlans);
    }

    fetchFromChain();
  }

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useInterval(async () => {
  //   await refresh();
  // }, 5000);


  return <div className="mt-4 lg:mt-8">
    <span className="inline-block text-2xl lg:text-4xl my-4 mt-8 underline text-center w-full">Plans</span>
    <div className="w-full max-w-6xl justify-between mx-auto flex flex-wrap">
      {plans.slice(0, 3).map((plan, index) =>
        <div className="w-full md:w-1/2 lg:w-1/3 mx-auto max-w-xl" key={index}>
          <Plan
            planId={index + 1}
            dailyProfit={`${plan[0].toFixed(1)}%`}
            totalProfit={`${plan[2].toFixed(1)}%`}
            days={plan[1]}
            withdrawn={"AnyTime"}
          />

        </div>
      )}
    </div>
    <div className="w-full max-w-6xl justify-between mx-auto flex flex-wrap mt-8">
      {plans.slice(3).map((plan, index) =>
        <div className="w-full md:w-1/2 lg:w-1/3 mx-auto max-w-xl" key={index}>
          <Plan
            planId={index + 4}
            dailyProfit={`${plan[0].toFixed(1)}%`}
            totalProfit={`${plan[2].toFixed(1)}%`}
            days={plan[1]}
            withdrawn={"End of Plan"}
          // account={account}
          // sendTransaction={sendTransaction}
          />

        </div>
      )}
    </div>
  </div>
}

export default Plans;