import useInterval from "@use-it/interval";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import useWeb3 from "../../hooks";
import { convertToEther, getContract } from "../../utilsv2";
import { ToastPopup } from "../Tutotial";

const UserStats = () => {
  const [totalDeposit, setTotalDeposit] = useState({ prev: 0, current: 0 });
  const [totalAvailable, setTotalAvailable] = useState({ prev: 0, current: 0 });

  const web3 = useWeb3();
  const contract = getContract(web3);
  const { account } = useWeb3React();

  const Icon = () => {
    return (
      <div className="h-16 w-16 mr-8">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z" clipRule="evenodd" />
        </svg>
      </div>
    )
  }

  const Locked = () => {
    return (
      <div className="h-16 w-16 mr-8">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" />
        </svg>
      </div>
    )

  }

  const refresh = async () => {
    console.log(`Refreshing User Deposits`);

    if (!account) return;
    const fetchFromChain = async () => {
      const deposit = await contract.methods.getUserTotalDeposits(account).call();
      const available = await contract.methods.getUserAvailable(account).call();

      return [deposit, available];
    }

    fetchFromChain().then(result => {
      const [deposit, available] = result;
      setTotalAvailable({ prev: totalAvailable.current, current: convertToEther(available, 6) });
      setTotalDeposit({ prev: totalDeposit.current, current: convertToEther(deposit) })
    })

  }

  useInterval(async () => await refresh(), 5000);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const withdrawBNB = async () => {
    contract.methods.withdraw().send({ from: account })
      .then(() => ToastPopup("Transaction Confirmed"))
      .catch((e) => {
        if (e.message.includes('User denied transaction signature')) {
          ToastPopup("User denied transaction signature", "error");
          return;
        }
        ToastPopup(e.message);
      });
  }

  return <>
    <div className="h-full text-black rounded mr-2 bg-cover flex flex-col justify-between">
      <div className="w-full mt-4 lg:mt-0 flex bg-indigo-600 rounded-xl flex-col justify-center shadow-2xl	">
        <div className="mt-2  text-white p-3 w-full max-w-xs rounded-xl mt-0 lg:mt-4 flex items-center mx-0 md:mx-4">
          <Icon />
          <div>
            <div className="font-medium text-4xl md:text-5xl">{totalDeposit.current}</div>
            <div className="text-sm font-medium">Total BNB Deposited</div>
          </div>
        </div>
        <div className="bg-indigo-600 text-white p-3 rounded-xl w-full max-w-xs mt-2 lg:mt-4 flex items-center mx-0 md:mx-4">
          <Locked />
          <div>
            <div className="font-medium text-4xl md:text-5xl ">{totalAvailable.current}</div>
            <div className="text-sm font-medium">Available for Withdrawal</div>
          </div>
        </div>
        <div className=" flex items-center mx-auto my-6">
          <button onClick={withdrawBNB} className=" bg-yellow-400 shadow text-indigo-600 py-3 w-full shadow-8xl mx-4 text-lg whitespace-nowrap md:text-2xl text-center font-medium py-2 px-8 hover:border-red-800 rounded-xl">Withdraw BNB</button>
        </div>
      </div>

    </div>
  </>
}

export default UserStats;