import useInterval from "@use-it/interval"
import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import useWeb3 from "../../hooks"
import { convertToEther, getContract } from "../../utils"
// import UserStats from "../UserStats"

var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const formatDate = d => {
  const date = new Date(d);
  return date.getDate() + " " + monthShortNames[date.getMonth()];
}

const Tag = ({ type }) => {
  return <span className={`rounded-xl px-2 text-xs text-indigo-600 ${type === 'active' ? ' bg-white' : ' bg-red-400'} px-2 `}>
    {type}
  </span>
}

const PlanDate = ({ from, to }) => {
  return <span className="text-xs">
    <span>{from}</span>
    <span className="mx-1">👉🏻</span>
    <span>{to}</span>
  </span>
}
const UserPlan = ({ investment, planId, profit, start, end }) => {
  const percent = Number(((Date.now() - start) / (end - start)) * 100).toFixed(0);
  return <div className="w-full md:w-1/2 lg:w-1/3 px-2 mt-2 max-w-xl mx-auto md:mx-0 text-white">
    <div className="border p-2 bg-indigo-600 rounded-xl shadow text-white">
      <div className="w-full px-2 text-xs p-2">
        <div className="flex justify-between items-center">
          <span className="font-semibold">{"Plan "}{planId}</span>
          <Tag type={`${end > Date.now() ? "active" : "expired"}`} />
          <PlanDate from={formatDate(start)} to={formatDate(end)} />
        </div>
        <div className="flex justify-between mt-6 bnb">
          <div className="flex-1">
            <div className="font-medium text-3xl text-white">{investment}</div>
            <div className="text-xs font-medium text-indigo-200">BNB</div>
          </div>
          <div className="flex-1">
            <div className="font-medium text-3xl text-white">{profit}</div>
            <div className="text-xs font-medium text-indigo-200"> BNB </div>
          </div>
        </div>
        <div>
          <div className="mt-2 bg-white rounded-full mt-6">
            <div className="mt-2 bg-green-500 py-0 rounded-full" style={{ width: `${Math.min(percent, 100)}%` }}>
              <div className=" text-white text-sm inline-block px-2 rounded-full">{Math.min(percent, 100)}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

const UserPlans = () => {
  const [investments, setInvestments] = useState({});
  const wallet = useWeb3React();
  const web3 = useWeb3();
  const contract = getContract(web3);

  const fetchUserInvestments = async () => {
    console.log(`Refreshing User Investments`);

    if (!wallet.account) return;
    let index = 0;
    let allInvestments = {};
    while (true) {
      try {
        const info = await contract.methods.getUserDepositInfo(wallet.account, index).call();
        allInvestments = { ...allInvestments, [index]: { "planId": info.plan, ...info } };
        ++index;
      } catch (e) {
        // console.error(e.message);
        break;
      }
    }
    setInvestments(allInvestments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchUserInvestments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(async () => {
    fetchUserInvestments();
  }, 5000);

  return <div className="w-full px-2 md:px-4 my-6">
    {Object.values(investments).length > 0 && <span className="inline-block text-2xl lg:text-3xl mt-4 mb-2 underline text-center w-full">Your Investments</span>}
    
    <div className="flex flex-wrap">
      {Object.values(investments).map((investment, index) => {
        return <>
          <UserPlan
            key={index}
            planId={parseInt(investment.planId) + 1}
            investment={convertToEther(investment.amount)}
            profit={convertToEther(investment.profit).toFixed(4)}
            start={investment.start * 1000}
            end={investment.finish * 1000}
          />
        </>
      })}
    </div>
  </div>
}

export default UserPlans;