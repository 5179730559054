import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AppV2 from './Appv2';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3';


window.console.warn = () => null


ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={provider => new Web3(provider)}>
      {/* <UseWalletProvider chainId={CHAIN_ID} connectors={{ walletconnect: { RPC_URL } }}> */}
      <CookiesProvider>
        <BrowserRouter>
          <div>
            <Switch>
              <Route path="/v2">
                <AppV2 />
              </Route>
              <Route path="/">
                <App />
              </Route>

            </Switch>
          </div>
        </BrowserRouter>
      </CookiesProvider>
      {/* </UseWalletProvider> */}
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
