// import { useEffect, useState } from 'react';
import { FaFileAlt, FaTelegram } from 'react-icons/fa';
import { CONTRACT_ADDRESS } from '../../utilsv2';
// import axios from 'axios';


const NavItem = ({ text, href }) => {
  return <a className="mx-1 md:mx-3" target="_blank" rel="noreferrer" href={href}>{text}</a>
}

// const BNBPrice = () => {

//   const [price, setPrice] = useState('...');

//   useEffect(() => {
//     axios.get('https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT')
//       .then(r => setPrice(Number(r.data.price).toFixed(2)))
//   }, [])

//   return <span className="mx-1 md:mx-3 py-1 px-2 lg:px-4 rounded-full font-medium">
//     <span className="bnb whitespace-nowrap mt-2 md:mt-0">1 BNB</span>
//     <span className="mx-1 text-indigo-800">=</span>
//     <span className="">${price}</span>
//   </span>
// }

const Nav = () => {
  return <>
    <nav className="w-full px-4 py-4 flex flex-wrap justify-center md:justify-between items-center py-2 max-w-6xl mx-auto border-b pb-6 border-indigo-100">
      <div className="inline-flex w-full lg:w-auto text-center">
        <a href="/" className="mx-auto">
          <div className="flex items-center">
            <img alt="logo" src="./logo.png" className="h-8 md:h-10" />
            <span className="text-3xl font-bold text-indigo-600 ml-2"> BNB Bank V2</span>
          </div>
        </a>
      </div>

      <div className="inline-flex justify-center lg:justify-end text-xs w-full lg:w-auto md:text-lg mt-2 lg:mt-0 font-medium text-indigo-800 items-center">
        {/* <BNBPrice /> */}
        <NavItem text="Audit" href="/bnbbank.pdf" />
        <NavItem text="Help" href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain" />
        <NavItem text="Support" href="https://t.me/bnbbankadmin" />
        <NavItem text={<span className="text-lg md:text-2xl"><FaFileAlt /></span>} href={`https://bscscan.com/address/${CONTRACT_ADDRESS}`} />
        <NavItem text={<span className="text-lg md:text-2xl"><FaTelegram /></span>} href="https://t.me/bnbbankapp" />
      </div>
    </nav>
  </>
}

export default Nav;