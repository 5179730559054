import { useWeb3React } from "@web3-react/core"
import { useState } from "react"
import { useCookies } from "react-cookie"
import useWeb3 from "../../hooks"
import { getContract } from "../../utilsv2"
import { ToastPopup } from "../Tutotial"

const address = "0xd3E76c1B53628d6De8398b2AF0Cd7b701137246D";


// const KeyValuePair = ({ input, value }) => {
//   return <>
//     <div className="flex-1 text-center">
//       <div className="font-medium text-3xl">{value}</div>
//       <div className="text-sm font-medium text-indigo-400">{input}</div>
//     </div>
//   </>
// }

const Row = ({ value, planId }) => {
  return <>
    <div className="text-center flex items-center ml-8 mt-8">
      <div className={`w-6 h-6 mr-2 ${planId >= 4 ? 'text-green-200' : 'text-indigo-200'}`}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
        </svg>
      </div>
      <div className="text-lg font-medium">{value}</div>
    </div>
  </>
}

const InvestmentRow = ({ bnb, setBnb }) => {
  return <>
    <div className="flex-1 text-center items-center justify-between flex mt-8 px-6">
      <input className="outline-none bg-gray-100 shadow text-indigo-600 border-2 rounded text-center py-2 w-full text-xl" type="number" value={bnb} onChange={({ target: { value } }) => setBnb(parseFloat(value) || 0)} />
      <div className="text-3xl ml-2">BNB</div>
    </div>
  </>
}

const InvestmentReturn = ({ value, input }) => {
  return <>
    <div className="flex-1 flex items-center text-center justify-center mt-2">
      <div className="font-medium text-2xl mx-2 underline">{value}</div>
      <div className="font-medium flex flex-col">
        <span>{`BNB you get in ${input} days`}</span>
      </div>
    </div>
  </>
}

const StakeBNB = ({ onClick, isGreen }) => {
  const cn = `bg-yellow-400 text-indigo-600`
  return <>
    <button onClick={onClick} className={`${cn} w-full shadow-8xl mx-4 text-2xl text-center font-medium py-2 px-8 hover:border-red-800 rounded-xl`}>Stake BNB</button>
  </>
}

const Plan = ({ dailyProfit, totalProfit, withdrawn, days, planId }) => {
  const [bnb, setBnb] = useState(1);
  const web3 = useWeb3();
  const contract = getContract(web3);
  const [cookies] = useCookies(['ref']);
  const { account } = useWeb3React();

  const sendTransaction = async (planId, bnb) => {
    const bnbVal = web3.utils.toWei(bnb.toString(), "ether");
    contract.methods.invest(web3.utils.isAddress(cookies.ref) ? cookies.ref : address, planId).send({ value: bnbVal, from: account })
      .then(() => ToastPopup("Transaction Confirmed"))
      .catch((e) => {
        if (e.message.includes('User denied transaction signature')) {
          ToastPopup("User denied transaction signature", "error");
          return;
        }
        ToastPopup(e.message);
      });
  }

  return <>
    <div className={`w-full px-2 mt-4 ${planId >= 4 ? 'text-green-300' : 'text-white'}`}>
      <div className=" w-full flex justify-center">
        <div style={{ zIndex: 1000 }} className="text-4xl font-semibold h-32 w-32 relative border-4 -mb-10 z-1 border-gray-200 flex items-center justify-center bg-indigo-600 text-center rounded-full">
          <div>{dailyProfit}</div>
        </div>
      </div>
      <div className={`py-8 shadow-xl bg-indigo-600 rounded-xl border border-t-8 border-indigo-500 border-gradient`}>
        <Row planId={planId} value={<span><span className="border-b font-medium">Dividends</span> every second</span>} />
        <Row planId={planId} value={planId >= 4 ? <span><span className="border-b">Withdraw </span> in {days} days</span> : <span><span className="border-b">Withdraw</span> anytime</span>} />
        <Row planId={planId} value={<span>Min Investment <span className="border-b">0.01 BNB</span></span>} />
        <Row planId={planId} value={<span>Ends in <span className="border-b">{days}</span> days</span>} />

        <div className="mt-12">
          <InvestmentRow bnb={bnb} setBnb={setBnb} />
        </div>
        <div className="flex mt-6 mx-2">
          <StakeBNB isGreen={planId >= 4} onClick={() => sendTransaction(planId - 1, bnb)} />
        </div>
        <InvestmentReturn input={days} value={((parseFloat(totalProfit) * bnb / 100)).toFixed(2)} />
      </div>
    </div>
  </>
}

export default Plan;